import $ from 'jquery';

$.fn.spieltagsauswahl = function () {
    this.filter(".spieltagsauswahl").each(function () {
        const spieltagsauswahl = $(this);
        const bonustab = $(this).find('.tab.bonus');
        const dropdownoverlay = $(this).find('.dropdownoverlay');
        const prevnextPrev = $(this).find('.prevnextPrev');
        const prevnextPrevAnchor = $(this).find('.prevnextPrev a');
        init();

        function init() {
            if (bonustab.length) {
                bonustabOverflow();
                $(window).resize(bonustabOverflow);
            }
        }

        function bonustabOverflow() {
            bonustab.show();
            if (isElementInViewport(bonustab)) {
                dropdownoverlay.find('.bonusoption').remove();
            } else {
                addBonusOption();
                bonustab.hide();
            }
            updatePrevElement();
        }

        function updatePrevElement() {
            const activeOption = dropdownoverlay.find('.level0.active-true');
            if (activeOption.prev('.level0').length) {
                prevnextPrev.removeClass('disabled');
            } else {
                prevnextPrev.addClass('disabled');
                prevnextPrevAnchor.removeAttr('href')
            }
        }

        function addBonusOption() {
            if (!dropdownoverlay.find('.bonusoption').length) {
                const active = bonustab.hasClass('active-true');
                const href = bonustab.find('a').attr('href');
                const title = bonustab.find('a').html();
                const firstOption = dropdownoverlay.find('.level0').first();
                const bonusOption = $('<div class="level0 bonusoption active-' + active + '"><a href="' + href + '">' + title + '</a></div>');
                bonusOption.insertBefore(firstOption);
            }
            if (!prevnextPrevAnchor.attr("href")) {
                const href = bonustab.find('a').attr('href');
                prevnextPrevAnchor.attr("href", href);
            }
        }

        function isElementInViewport(el) {
            const rect = el[0].getBoundingClientRect();
            const container = spieltagsauswahl[0].getBoundingClientRect();
            return rect.right <= container.right;
        }
    });

    return this;
};

window.addEventListener('DOMContentLoaded', function () {
    $(".spieltagsauswahl").spieltagsauswahl();
});
