import $ from 'jquery';

$.fn.prevnextauswahl = function () {
    this.filter(".prevnextauswahl").each(function () {
        const prevnexttab = $(this).find('.tab.prevnext');
        const dropdownoverlay = $(this).find('.dropdownoverlay');
        const prevnextTitle = $(this).find('.prevnextTitle');
        init();

        function init() {
            prevnextTitle.click(toggleDropdown);
            $(document).on("click", function () {
                if (dropdownoverlay.hasClass("dropdown-active")) {
                    hideDropdown();
                }
            });
        }

        function hideDropdown() {
            dropdownoverlay.removeClass("dropdown-active");
            dropdownoverlay.hide();
        }

        function toggleDropdown() {
            if (prevnexttab.hasClass('active-false')) {
                return true;
            }
            if (dropdownoverlay.hasClass("dropdown-active")) {
                hideDropdown();
            } else {
                $(".dropdownoverlay").hide();
                dropdownoverlay.addClass("dropdown-active");
                dropdownoverlay.show();
            }
            return false;
        }
    });

    return this;
};

window.addEventListener('DOMContentLoaded', function () {
    $(".prevnextauswahl").prevnextauswahl();
});
