import $ from 'jquery';

$.ktable = function (element) {
    const reflowOperations = [];
    const reflowCallbacks = [];
    const resetOperations = [];
    const resetCallbacks = [];
    const columnIndexByName = {};
    const operationStatus = [];
    let columnsToggled = 0;
    const plugin = this;
    const ktable = $(element);
    const wrapper = $('body');
    let width = 0;

    plugin.create = function () {
    }

    plugin.keep = function (selector) {
        $(selector).addClass("nodatarow");
    }

    plugin.hide = function (cols, config) {
        pushOperation(cols, config, hideOn, hideOff);
    }

    plugin.rowheader = function (cols, config) {
        pushOperation(cols, config, rowheaderOn, rowheaderOff);
    }

    plugin.toggle = function (cols, config) {
        pushOperation(cols, config, toggleOn, toggleOff);
    }

    plugin.wrap = function (cols, config) {
        pushOperation(cols, config, wrapOn, wrapOff);
    }

    plugin.shrink = function (cols, config) {
        pushOperation(cols, config, shrinkOn, shrinkOff);
    }

    plugin.wordwrap = function (cols, config) {
        pushOperation(cols, config, wordwrapOn, wordwrapOff);
    }

    plugin.overflow = function (cols, config) {
        pushOperation(cols, config, overflowOn, overflowOff);
    }

    plugin.stack = function (cols, config) {
        pushOperation(cols, config, stackOn, stackOff);
    }

    plugin.prepare = function () {
        initColumnIndexByName();
        addColumnClasses();
        addRowClasses();
        ktable.addClass("ktable");
    }

    plugin.reflow = function () {
        $(window).resize(windowResize);
        windowResize();
    }

    plugin.init = function () {
        plugin.prepare();
        plugin.reflow();
    }

    plugin.doReflow = function (index) {
        if (typeof reflowOperations[index] == 'function') {
            if (operationStatus[index] === 'reset') {
                reflowOperations[index].call();
                operationStatus[index] = 'reflow';
                reflowCallback(index);
            }
        }
    }

    let windowResize = function () {
        if (width !== $(window).width()) {
            if (width !== 0) {
                // reset nicht notwendig beim ersten Aufruf
                resetTable();
            }
            reflowTable();
            width = $(window).width();
        }
    }

    let resetTable = function () {
        let i = 0;
        while (i < resetOperations.length) {
            if (operationStatus[i] === 'reflow') {
                resetOperations[i].call();
                operationStatus[i] = 'reset';
                resetCallback(i);
            }
            i++;
        }
        ktable.addClass("plain");
    }

    let reflowTable = function () {
        let i = 0;
        const l = reflowOperations.length;
        while (ktable.getPixelNotInViewport(wrapper) > 0 && i < l) {
            ktable.removeClass("plain");
            if (operationStatus[i] === 'reset') {
                reflowOperations[i].call();
                operationStatus[i] = 'reflow';
                reflowCallback(i);
            }
            i++;
        }
    }

    let rowheaderOn = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            const index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                hideOn([cols[i]]);
                let lastHeader = "";
                ktable.find(">tbody >tr >td.col" + index).each(function () {
                    const td = $(this);
                    const tr = td.closest('tr');
                    let thisRowheader = td.html();
                    if (thisRowheader !== lastHeader && thisRowheader.length > 0) {
                        tr.before('<tr class="rowheader"><td colspan="99">' + thisRowheader + '</td></tr>');
                        lastHeader = thisRowheader;
                    }
                });
            }
        }
        ktable.addClass("rowheader");
    }

    let rowheaderOff = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== undefined) {
                hideOff([cols[i]]);
                ktable.find("tbody > tr.rowheader").remove();
            }
        }
        ktable.removeClass("rowheader");
    }

    let hideOn = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                ktable.find(">tbody >tr >.col" + index).addClass("hide");
                ktable.find(">thead >tr >.col" + index).addClass("hide");
            }
        }
        ktable.addClass("hide");
    }

    let hideOff = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                ktable.find(">tbody >tr >.col" + index).removeClass("hide");
                ktable.find(">thead >tr >.col" + index).removeClass("hide");
            }
        }
        ktable.removeClass("hide");
    }

    let toggleOn = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                let lastHeader = "";
                hideOn([cols[i]]);
                if (columnsToggled === 0) {
                    // add toggle icon
                    ktable.find(">thead >tr.datarow").each(function () {
                        $(this).prepend('<th class="toggle"> </th>')
                    });
                    // add toggle icon
                    ktable.find(">tbody >tr.datarow").each(function () {
                        $(this).prepend('<td class="toggle hover"><span class="kicktipp-icon-arrow-down2"> </span></td>')
                    });
                    // add toggle click handler
                    ktable.find("tbody tr.datarow td.toggle").click(function (e) {
                        e.preventDefault();
                        $(this).closest('tr').next('tr').toggleClass("show");
                        return false;
                    });
                }
                let thHtml = ktable.find(">thead >tr.datarow >th.col" + index).html();

                ktable.find(">tbody >tr >td.col" + index).each(
                    function () {
                        let td = $(this);
                        let tr = td.closest('tr');
                        // toggle row
                        let togglerow = '<tr>';
                        togglerow += '<td>';
                        togglerow += '<div class="togglehead">' + thHtml + '</div>';
                        togglerow += '<div class="toggledata' + index + '" >' + td.html() + '</div>';
                        togglerow += '</td>';
                        togglerow += '</tr>';
                        if (tr.next(".rowtoggle").length) {
                            // append to given toggle row
                            tr.next(".rowtoggle").find("table.toggle").append(togglerow)
                        } else {
                            // add new toggle row
                            tr.after('<tr class="rowtoggle"><td class="rowtogglehead"></td><td class="rowtoggledata" colspan="99"><table class="toggle"><tbody>'
                                + togglerow + '</tbody></table></td></tr>');
                        }
                    });
                columnsToggled++;
            }
        }
        ktable.addClass("toggle");
    }

    let toggleOff = function (cols) {
        ktable.find(">tbody >tr.rowtoggle, >* >tr >.toggle").remove();
        columnsToggled = 0;
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                hideOff([cols[i]]);
            }
        }
        ktable.removeClass("toggle");
    }

    let wrapOn = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                ktable.find(">* >tr >td.col" + index).addClass("wrap");
            }
        }
        ktable.addClass("wrap");
    }

    let wrapOff = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                allColumns(index).removeClass("wrap");
            }
        }
        ktable.removeClass("wrap");
    }

    let shrinkOn = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                ktable.find(">* >tr >td.col" + index).addClass("shrink");
            }
        }
        ktable.addClass("shrink");
    }

    let shrinkOff = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                allColumns(index).removeClass("shrink");
            }
        }
        ktable.removeClass("shrink");
    }

    let wordwrapOn = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                allColumns(index).addClass("wordwrap");
            }
        }
        ktable.addClass("wordwrap");
    }

    let wordwrapOff = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                allColumns(index).removeClass("wordwrap");
            }
        }
        ktable.removeClass("wordwrap");
    }

    let overflowOn = function (cols) {
        // erst class=overflow, damit die Breiten richtig berechnet werden können.
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                allColumns(index).addClass("overflow");
            }
        }
        // dann die neuen Breiten berechnen
        let pixel = ktable.getPixelNotInViewport(wrapper) / cols.length;
        let newWidth = [];

        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                let oldWidth = bodyColumns(index).first().width();
                newWidth[i] = oldWidth - pixel;
            }
        }

        // dann anwenden
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                allColumns(index).css("max-width", newWidth[i] + "px");
            }

        }
        ktable.addClass("overflow");
    }

    let overflowOff = function (cols) {
        for (let i = 0; i < cols.length; i++) {
            let index = resolveColumnIndex(cols[i]);
            if (typeof index !== 'undefined') {
                allColumns(index).removeClass("overflow").css("max-width", "none");
            }
        }
        ktable.removeClass("overflow");
    }

    let stackOn = function (cols, config) {
        let indexStack = resolveColumnIndex(cols[0]);
        if (typeof indexStack !== 'undefined') {
            for (let i = 1; i < cols.length; i++) {
                let index = resolveColumnIndex(cols[i]);
                if (typeof index !== 'undefined') {
                    hideOn([cols[i]]);
                }
            }
            ktable.find(">tbody >tr").each(function () {
                for (let i = 1; i < cols.length; i++) {
                    let index = resolveColumnIndex(cols[i]);
                    if (typeof index !== 'undefined') {
                        stackTD(this, indexStack, index, config)
                    }
                }
            });
            ktable.find(">thead >tr").each(function () {
                for (let i = 1; i < cols.length; i++) {
                    let index = resolveColumnIndex(cols[i]);
                    if (typeof index !== 'undefined') {
                        stackTH(this, indexStack, index, config)
                    }
                }
            });
        }
    }

    let stackOff = function (cols) {
        let indexStack = resolveColumnIndex(cols[0]);
        if (typeof indexStack !== 'undefined') {
            ktable.find(">tbody >tr").each(function () {
                let tr = $(this);
                tr.find("td.col" + indexStack + " div.stack .stackElement").each(function () {
                    let fromCol = $(this).attr("data-from")
                    let content = $(this).contents();
                    tr.find("td.col" + fromCol).append(content);
                    tr.find("td.col" + fromCol + " .stackElementLabel").remove();
                });
                tr.find("td.col" + indexStack + " div.stack").remove();
            });
            ktable.find(">thead >tr").each(function () {
                let tr = $(this);
                tr.find("th.col" + indexStack + " div.stack .stackElement").each(function () {
                    let fromCol = $(this).attr("data-from")
                    let content = $(this).contents();
                    tr.find("th.col" + fromCol).append(content);
                });
                tr.find("th.col" + indexStack + " div.stack").remove();
            });
            for (let i = 1; i < cols.length; i++) {
                let index = resolveColumnIndex(cols[i]);
                if (typeof index !== 'undefined') {
                    hideOff([cols[i]]);
                }
            }
        }
    }

    let stackTD = function (tr, indexStack, indexStackElement, config) {
        let stack = getStack(tr, indexStack);
        let stackElement = createStackElement(tr, indexStackElement, config);
        let priorityStackElement = $(stackElement).data('priority');
        insertStackElement(stack, stackElement, indexStackElement, priorityStackElement);
    }

    let stackTH = function (tr, indexStack, indexStackElement, config) {
        let stack = getStackTH(tr, indexStack);
        let stackElement = createStackElementTH(tr, indexStackElement, config);
        let priorityStackElement = $(stackElement).data('priority');
        insertStackElement(stack, stackElement, indexStackElement, priorityStackElement);
    }

    let createStackElement = function (tr, indexStackElement, config) {
        let stackElement = $("<div>",
            {
                class: "stackElement"
            });
        stackElement.attr("data-from", indexStackElement);
        let content = $(tr).find('td.col' + indexStackElement).contents();
        if (config.singleRow) {
            stackElement.addClass('singlerow');
        }
        if (config.prependTablehead) {
            stackElement.addClass('withlabel');
            let label = ktable.find('>thead >tr >th.col' + indexStackElement).html();
            if (label && label.length > 0) {
                let stackElementLabel = $("<span>",
                    {
                        class: "stackElementLabel"
                    });

                stackElementLabel.append(label + ": ");
                stackElement.append(stackElementLabel)
            }
        }
        stackElement.append(content);
        stackElement.data('columnIndex', indexStackElement);
        let priority = config.priority;
        if (!$.isNumeric(priority) || priority < 0) {
            priority = 0;
        }
        stackElement.data('priority', priority);
        return stackElement;
    }

    let createStackElementTH = function (tr, indexStackElement, config) {
        let stackElement = $("<span>",
            {
                class: "stackElement"
            });
        stackElement.attr("data-from", indexStackElement);
        let content = $(tr).find('th.col' + indexStackElement).contents();
        if (content.length) {
            stackElement.addClass('withContent');
        }
        stackElement.append(content);
        stackElement.data('columnIndex', indexStackElement);
        let priority = config.priority;
        if (!$.isNumeric(priority) || priority < 0) {
            priority = 0;
        }
        stackElement.data('priority', priority);
        return stackElement;
    }

    let getStack = function (tr, indexStack) {
        let stack = $(tr).find('td.col' + indexStack + ' .stack');
        if (!stack.length) {
            stack = $("<div>",
                {
                    class: "stack"
                });
            let stackElement = createStackElement(tr, indexStack, false);
            stack.append(stackElement);
            $(tr).find('td.col' + indexStack).html(stack);
        }
        return stack;
    }

    let getStackTH = function (tr, indexStack) {
        let stack = $(tr).find('th.col' + indexStack + ' .stack');
        if (!stack.length) {
            stack = $("<div>",
                {
                    class: "stack"
                });
            let stackElement = createStackElementTH(tr, indexStack, false);
            stack.append(stackElement);
            $(tr).find('th.col' + indexStack).html(stack);
        }
        return stack;
    }

    let insertStackElement = function (stack, stackElement, indexStackElement, priorityStackElement) {
        let inserted = false;
        stack.find(".stackElement").each(function () {
            let columnIndex = $(this).data('columnIndex');
            let priority = $(this).data('priority');
            if (!inserted) {
                if (priorityStackElement < priority) {
                    $(this).before(stackElement);
                    inserted = true;
                } else if (priorityStackElement === priority && indexStackElement < columnIndex) {
                    $(this).before(stackElement);
                    inserted = true;
                }
            }
        });
        if (!inserted) {
            stack.append(stackElement);
        }
    }

    let pushOperation = function (cols, config, reflowOperation, resetOperation) {
        config = typeof config !== 'undefined' ? config : {};
        reflowOperations.push(reflowOperation.bind(ktable, cols, config));
        reflowCallbacks.push(config.reflowCallback);
        resetOperations.push(resetOperation.bind(ktable, cols, config));
        resetCallbacks.push(config.resetCallback);
        operationStatus.push('reset');
    }

    let allColumns = function (index) {
        return ktable.find(">* >tr >.col" + index);
    }

    let bodyColumns = function (index) {
        return ktable.find(">tbody >tr >.col" + index);
    }

    let headColumns = function (index) {
        return ktable.find(">thead >tr >.col" + index);
    }

    let resolveColumnIndex = function (col) {
        if (typeof col === "string") {
            return columnIndexByName[col];
        }
        return col;
    }

    let initColumnIndexByName = function () {
        let index = 0;
        ktable.find(">thead >tr:first th").each(function () {
            if ($(this).index() === 0) {
                index = 0;
            }
            let name = $(this).attr('name');
            columnIndexByName[name] = index;
            index = index + getColspan(this);
        });
    }

    let addColumnClasses = function () {
        let index = 0;
        ktable.find(">tbody >tr:not('.nodatarow') >td, >thead >tr >th").each(function () {
            if ($(this).index() === 0) {
                index = 0;
            }
            $(this).addClass("cell col" + index);
            index = index + getColspan(this);
        });
    }

    let addRowClasses = function () {
        ktable.find(">* >tr:not('.nodatarow')").addClass("datarow");
    }

    let getColspan = function (element) {
        let colspan = $(element).attr('colspan');
        // For some browsers, `attr` is undefined; for others,
        // `attr` is false. Check for both.
        if (typeof colspan === typeof undefined || colspan === false) {
            colspan = 1;
        }
        return parseInt(colspan);
    }

    let reflowCallback = function (index) {
        if (typeof reflowCallbacks[index] == 'function') {
            reflowCallbacks[index].call(this);
        }
    }

    let resetCallback = function (index) {
        if (typeof resetCallbacks[index] == 'function') {
            resetCallbacks[index].call(this);
        }
    }

    plugin.create();
}

$.fn.ktable = function (options) {
    return this.each(function () {
        if (undefined == $(this).data('ktable')) {
            let plugin = new $.ktable(this, options);
            $(this).data('ktable', plugin);
        }
    });
}
