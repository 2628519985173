import $ from 'jquery'

$.fn.isVisible = function (container) {
    return this.getPixelNotInViewport(container) === 0;
};

$.fn.getPixelNotInViewport = function (container) {
    let tRight;
    let cRight;
    if (typeof this.get(0).getBoundingClientRect === 'function') {
        tRight = this.get(0).getBoundingClientRect().right;
        cRight = container.get(0).getBoundingClientRect().right;
    } else {
        tRight = this.offset().left + this.width();
        cRight = container.offset().left + container.width();
    }
    return Math.max(0, tRight - cRight);
}
