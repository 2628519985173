import $ from 'jquery'

$.fn.ktabs = function () {
    this.filter(".ktabs").each(function () {
        const ktabs = $(this);
        let dropdownOverlay;
        let optionOverlay;
        let optionTab;
        let optionTabWidth = 0;

        function initKtabs() {
            initOptionOverlay();
            buildDropdown();
            tabOverflow();
            $(window).resize(tabOverflow);
            $(document).click(function (event) {
                handleDocumentClick(event);
            });
        }

        function initOptionOverlay() {
            optionOverlay = ktabs.find(".optionoverlay");
            optionTab = ktabs.find(".optiontab");
            if (optionOverlay.length && optionTab.length) {
                optionTabWidth = optionTab[0].getBoundingClientRect().right - optionTab[0].getBoundingClientRect().left;
                optionTab.click(toggleOptionDropdown);
            }
        }

        function handleDocumentClick(event) {
            let overlays = $(event.target).closest(dropdownOverlay);
            if (!overlays.length) {
                hideDropdown();
            }
            overlays = $(event.target).closest(optionOverlay);
            if (!overlays.length) {
                hideOptionOverlay();
            }
        }

        function hideDropdown() {
            ktabs.find(".tabs.dropdown").removeClass("dropdown-active");
            dropdownOverlay.hide();
        }

        function toggleDropdown() {
            if (optionTab.hasClass("option-active")) {
                hideOptionOverlay();
            }
            if (ktabs.find(".tabs.dropdown").hasClass("dropdown-active")) {
                hideDropdown();
            } else {
                // Wenn es mehrere ktabs auf der Seite gibt,
                // erst alle schließen
                $(".tabs.dropdown").removeClass("dropdown-active");
                $(".dropdownoverlay").hide();
                ktabs.find(".tabs.dropdown").addClass("dropdown-active");
                dropdownOverlay.css("display", "inline-block");
            }
            return false;
        }

        function hideOptionOverlay() {
            optionTab.removeClass("option-active");
            optionOverlay.hide();
        }

        function toggleOptionDropdown() {
            if (ktabs.find(".tabs.dropdown").hasClass("dropdown-active")) {
                hideDropdown();
            }
            if (optionTab.hasClass("option-active")) {
                hideOptionOverlay();
            } else {
                // Wenn es mehrere ktabs auf der Seite gibt,
                // erst alle schließen
                $(".optiontab").removeClass("option-active");
                $(".optionoverlay").hide();
                optionTab.addClass("option-active");
                optionOverlay.css("display", "inline-block");
            }
            return false;
        }

        function isElementInViewport(el) {
            const rect = el[0].getBoundingClientRect();
            const containerLimit = ktabs[0].getBoundingClientRect().right - optionTabWidth;
            return rect.right <= containerLimit;
        }

        function buildDropdown() {
            dropdownOverlay = $('<div class="dropdownoverlay"><div class="dropdowncontent"><div class="menu"><!-- --></div></div></div>');
            ktabs.append(dropdownOverlay);
            const menu = ktabs.find(".dropdowncontent .menu");
            ktabs.find(".tabs .tab a").each(function () {
                const active = $(this).parent().hasClass("active-true");
                $('<div class="level0 active-' + active + '"> </div>').append($(this).clone()).appendTo(menu);
            });
        }

        function tabOverflow() {
            const lastTab = ktabs.find(".tabs .tab").last();
            ktabs.find(".tabs .tab.active-false").css("display", "inline-block");
            ktabs.find(".tabs.dropdown .active-true").off();
            ktabs.find(".tabs").removeClass("dropdown");
            if (isElementInViewport(lastTab)) {
                hideDropdown();
                ktabs.find(".tabs .tab").css("visibility", "visible");
                ktabs.find(".tabs.dropdown .tab.active-true").unbind('click.dropdown', toggleDropdown);
                ktabs.find(".tabs").removeClass("dropdown");
                const activeTabAnchor = ktabs.find(".tabs .tab.active-true a");
                activeTabAnchor.attr('href', activeTabAnchor.data('url'));
                activeTabAnchor.data('url');
            } else {
                ktabs.find(".tabs .tab.active-false").hide();
                ktabs.find(".tabs").addClass("dropdown");
                ktabs.find(".tabs.dropdown .tab.active-true").bind('click.dropdown', toggleDropdown);
                const activeTabAnchor = ktabs.find(".tabs .tab.active-true a");
                activeTabAnchor.data('url', activeTabAnchor.attr('href'));
                activeTabAnchor.removeAttr('href');
            }
        }
        initKtabs();
    });

    return this;
};

window.addEventListener('DOMContentLoaded', function () {
    $(".ktabs").ktabs();
});
