import $ from 'jquery'

$.fn.pagetitle = function () {
    const pagetitle = $(this);
    let seitenmenueIcon;
    let seitenmenue;

    function initSeitenmenue() {
        seitenmenueIcon = pagetitle.find(".pagetitleSeitenmenueIcon");
        seitenmenue = pagetitle.find(".pagetitleSeitenmenue");
        if (seitenmenueIcon.length && seitenmenue.length) {
            seitenmenueIcon.click(toggleSeitenmenue);
        }
        $(document).click(function (event) {
            handleDocumentClick(event);
        });
    }

    function handleDocumentClick(event) {
        seitenmenue.hide();
        seitenmenueIcon.removeClass("open");
    }

    function toggleSeitenmenue() {
        if (seitenmenueIcon.hasClass("open")) {
            hideSeitenmenu();
        } else {
            seitenmenueIcon.addClass("open");
            seitenmenue.show();
            seitenmenue.find("nav").show();
        }
        return false;
    }

    function hideSeitenmenu() {
        seitenmenueIcon.removeClass("open");
        seitenmenue.hide();
    }

    initSeitenmenue();
    return this;
};

window.addEventListener('DOMContentLoaded', function () {
    $("#pagetitle").pagetitle();
});
